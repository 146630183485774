<template lang="pug">
.container.pt-0
  XyzTransition(appear, xyz="fade right-5 up-5 duration-10 ease-in-out-back")
    .circle
  .box-hero
    .column-1
      XyzTransition(appear, xyz="fade up-1")
        h1 Crea tu mejor CV Rápidamente
      XyzTransition(appear, xyz="fade left-1")
        p Utiliza nuestras plantillas para currículum, revisadas y testeadas por profesionales. En pocos minutos tendras tu nuevo CV listo para descargar.
      XyzTransition(appear, xyz="fade down-1")
        .box-action-button(@click="onClickCreateCV")
          .rounded-2xl.bg-blue-600.text-white.font-bold.text-2xl.py-4.px-16.cursor-pointer.shadow-brand Crear mi CV
    .col-span-1
      .flex.justify-center.relative
        XyzTransition(
          appear,
          xyz="fade origin-right right-1 duration-10 ease-in-out-back"
        )
          .image-cv.shadow-brand.overflow-hidden
            picture
              source(:srcset="`/theme/resumes/12.png`", type="image/png")
              img(:src="`/theme/resumes/12.png`")
</template>
<script>
export default {
  name: 'hero',
  mounted() { },
  methods: {
    onClickCreateCV() {
      if (this.$store.getters['UserStore/getIsLogged']) {
        this.$router.push({ name: 'my-cvs' });
      } else {
        this.$router.push({ name: 'builder/select-template' });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.circle {
  position: absolute;
  width: 700px;
  height: 700px;
  top: 0px;
  right: 0px;
  z-index: -1;
  border-bottom-left-radius: 250px;
  @apply bg-blue-50;
}
.image-cv {
  width: 420px;
  border-radius: 6px;
  z-index: 1;
  img {
    width: 100%;
    max-width: 420px;
  }
}

.box-hero {
  @apply grid grid-cols-1 gap-12 mt-16 md:grid-cols-2 md:gap-6;

  .column-1 {
    @apply col-span-1 flex flex-col justify-center;

    h1 {
      @apply text-center text-3xl font-bold text-gray-700 mb-8 leading-tight md:-mt-20 md:text-5xl md:text-left;
    }

    p {
      @apply text-center text-gray-600 mb-8 md:text-xl md:text-left md:mb-12;
    }

    .box-action-button {
      @apply self-center md:self-start;
    }
  }
}
</style>
